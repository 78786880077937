<template>
  <IonApp>
    <ion-page>
      <MaxahHeader></MaxahHeader>
      <ion-content>
        <ion-router-outlet id="main-content"></ion-router-outlet>
      </ion-content>
      <MaxahFooter></MaxahFooter>
    </ion-page>
  </IonApp>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet, IonPage, IonContent } from "@ionic/vue";
import { defineComponent } from "vue";

import MaxahHeader from "@/components/Header.vue";
import MaxahFooter from "@/components/Footer.vue";
export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonPage,
    IonContent,
    MaxahHeader,
    MaxahFooter,
  }
});
</script>

<style scoped>
</style>