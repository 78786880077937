<template>
  <ion-button fill="clear" v-if="!mobileView" @click="router.push('/home')">
    Home
  </ion-button>
  <ion-button fill="clear" v-if="!mobileView" @click="router.push('/services')">
    Services
  </ion-button>
  <ion-button
    fill="clear"
    v-if="!mobileView"
    @click="router.push('/industries')"
  >
    Industries
  </ion-button>
  <!-- <ion-button
        fill="clear"
        @click="router.push('/portfolio')"
    >
        Portfolio
    </ion-button>
    <ion-button
        fill="clear"
        @click="router.push('/careers')"
    >
        Careers
    </ion-button> -->
  <ion-button fill="clear" v-if="!mobileView" @click="goTo('/about-us')">
    About Us
  </ion-button>
  <!-- Mobile Menu -->
  <ion-item button detail="false" fill="clear"  v-if="mobileView" @click="goTo('/home')"> Home </ion-item>
  <ion-item button detail="false" fill="clear"  v-if="mobileView" @click="goTo('/services')">
    Services
  </ion-item>
  <ion-item button detail="false" fill="clear"  v-if="mobileView" @click="goTo('/industries')">
    Industries
  </ion-item>
  <!-- <ion-item button detail="false" 
        fill="clear"
        @click="goTo('/portfolio')"
    >
        Portfolio
    </ion-item>
    <ion-item button detail="false" 
        fill="clear"
        @click="goTo('/careers')"
    >
        Careers
    </ion-item> -->
  <ion-item button detail="false" fill="clear"  v-if="mobileView" @click="goTo('/about-us')">
    About Us
  </ion-item>
</template>

<script>
import { IonButton, IonItem, popoverController } from "@ionic/vue";
import { menu } from "ionicons/icons";
import { useRouter } from "vue-router";
export default {
  name: "MaxahMenuItems",
  components: {
    IonButton,
    IonItem,
  },
  data() {
    const router = useRouter();
    const mobileView = window.innerWidth < 600;

    return {
      router,
      menu,
      mobileView,
      popoverController
    };
  },
  beforeUnmount() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.mobileView = window.innerWidth < 600;
    },
    goTo(route) {
      this.popoverController.dismiss();
      this.router.push(route);
    }
  },
};
</script>

<style scoped>
ion-button,
ion-toggle {
  vertical-align: middle;
}
ion-img {
  cursor: pointer;
}
</style>