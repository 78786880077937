
import { IonFooter, IonToolbar, IonText } from "@ionic/vue";

export default {
  name: "MaxahFooter",
  components: {
    IonFooter,
    IonToolbar,
    IonText
  },
  data() {
    const d = new Date();
    const currentYear = d.getFullYear();
    return { currentYear };
  },
};
