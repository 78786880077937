<template>
  <ion-footer no-border color="primary">
    <ion-toolbar
      ><div class="ion-text-center">
        <!-- <h3>Ready to build your custom application solution?</h3>
        <p>
          Send us your requirements on
          <a href="mailto:h.vora139@gmail.com">hello@maxah.in</a> or call us on
          <a href="tel:+916355275150">(635) 527 5150</a>
        </p> -->
        <ion-text color="yellow"
          ><p>&copy; Copyright {{currentYear}}, MaXah SSPL. All Rights Reserved.</p></ion-text
        >
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import { IonFooter, IonToolbar, IonText } from "@ionic/vue";

export default {
  name: "MaxahFooter",
  components: {
    IonFooter,
    IonToolbar,
    IonText
  },
  data() {
    const d = new Date();
    const currentYear = d.getFullYear();
    return { currentYear };
  },
};
</script>

<style scoped>
.header-logo-full {
  height: 45px;
  width: 145px;
}
</style>