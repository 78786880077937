
import { IonApp, IonRouterOutlet, IonPage, IonContent } from "@ionic/vue";
import { defineComponent } from "vue";

import MaxahHeader from "@/components/Header.vue";
import MaxahFooter from "@/components/Footer.vue";
export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonPage,
    IonContent,
    MaxahHeader,
    MaxahFooter,
  }
});
