<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row
          align-items-stretch
          justify-content-between
          class="ion-align-items-center"
        >
          <ion-col size="auto" class="ion-text-left">
            <ion-img
              class="header-logo-full"
              src="/assets/maxah.svg"
              @click="router.push('/home')"
            ></ion-img>
          </ion-col>
          <ion-col class="ion-align-items-center ion-text-right">
            <MaxahMenuItems v-if="!isMobile"></MaxahMenuItems>
            <ion-toggle
              color="primary"
              :checked="darkMode"
              @ionChange="toggleDarkMode()"
            >
            </ion-toggle>
            <ion-button
              v-if="isMobile"
              fill="clear"
              @click="showPopover($event)"
            >
              <ion-icon :icon="menu" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  popoverController,
  IonToggle,
} from "@ionic/vue";
import { menu } from "ionicons/icons";
import { useRouter } from "vue-router";
import MaxahMenuItems from "@/components/MenuItems.vue";
export default {
  name: "MaxahHeader",
  components: {
    IonHeader,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonImg,
    IonIcon,
    MaxahMenuItems,
    IonToggle,
  },
  data() {
    const router = useRouter();
    const darkMode = false;
    const isMobile = false;
    return {
      darkMode,
      router,
      isMobile,
      menu,
      popoverController,
    };
  },
  beforeUnmount() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    async showPopover(event) {
      const popover = await this.popoverController.create({
        event,
        component: MaxahMenuItems,
        cssClass: "my-custom-class", // optional
      });
      return await popover.present();
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    toggleDarkMode: function () {
      this.darkMode = !this.darkMode;
      document.body.classList.toggle("dark", this.darkMode);
    },
  },
};
</script>

<style scoped>
.header-logo-full {
  height: 45px;
  width: 145px;
}

ion-button,
ion-toggle {
  vertical-align: middle;
}
ion-img {
  cursor: pointer;
}
</style>