import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/services',
    component: () => import('@/views/Services.vue')
  },
  {
    path: '/industries',
    component: () => import('@/views/Industries.vue')
  },
  {
    path: '/portfolio',
    component: () => import('@/views/Portfolio.vue')
  },
  {
    path: '/careers',
    component: () => import('@/views/Careers.vue')
  },
  {
    path: '/about-us',
    component: () => import('@/views/AboutUs.vue')
  }
];
const scrollBehavior
  = (to, from, savedPosition) => new Promise((resolve) => {
    if (savedPosition) {
      return { x: 0, y: 0 };
      // resolve(savedPosition);
      // return savedPosition;
    } else {
      const position: any = {};
      if (to.hash) {
        position.selector = to.hash;
        position.behavior = 'smooth';
        if (document.querySelector(to.hash)) {
          resolve(position);
          return position;
        }
        return false;
      }
      if (to.matched.some((m) => m.meta.scrollToTop)) {
        position.x = 0;
        position.y = 0;
        resolve(position);
        return position;
      }
    }
  });

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
});

export default router
